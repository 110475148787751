
.wrapper {
  display: flex;
  flex-direction: column;
  align-content: right;
  justify-content: right;
  background: white;
  width: 100%;
}
.header {
  display: flex;
  flex-direction: row; 
  align-content: right;
  justify-content: right;
  /*height: 100%;*/
  width: 80%;
  padding: 10px;
}
.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 85vh;
  background-size: cover;
  background: white;
}
.content {
  min-height: 200px;
  width: 80%;
  margin: 30px auto;
  background: white;
  border-radius: 10px;
  padding: 32px;
  box-shadow: 0px 4px 6px #00000029;
}
.border {
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 0px 2px;
}
.footer {
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 0px 2px;
}
.header {
  margin-left: 30;
  margin-right: auto;
  font-size: 32px;
  font-weight: bold;
}
.subHeader {
  font-size: 10px;
}
.content p {
  font-size: 15px;
}

.tablist {
  background: white;
}
.footer {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  color: black;
}

.footerLeftLink {
  margin-left: auto;
  margin-right: 120;
  color: black;
}

.footerRightLink {
  margin-left: 120;
  margin-right: auto;
  color: black;
}

.Timestamp {
  text-align: center;
  font-size: 1.5em; 
  margin-bottom: 20px;
  font-weight: bold;
}

.Images {
  display: flex;
  flex-wrap: wrap;
}

.Image {
  width: 25%;
  text-align: center;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.Image img {
  padding: 3px;
  border: 3px solid rgba(255, 255, 255, 0);
  width: 100px;
  height: 100px;
}

.Image img.Border {
  border: 3px solid rgba(255, 0, 0, 1);
}
.Red {
  color: red;
}

.ImageName {
  text-align: center;
  margin: 5px auto;
  font-size: 1.3em;
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  display: block;
  width: fit-content;
}

.player-wrapper {
  position: relative;
  padding-top: 1%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}