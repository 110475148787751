.home-header {
  display: flex;
  flex-direction: row; 
  align-content: right;
  justify-content: right;
  /*height: 100%;*/
  width: 100%;
  padding: 10px;
}
.home {
    display: flex;
    flex-direction: row; 
    align-content: left;
    justify-content: left;
    /*height: 100%;*/
    width: 100%;
    padding: 10px;
  }